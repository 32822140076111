<template>
    <div class="page-body min-vh-100">
        <div class="col-md-4 error-left">
            <img src="/img/Group 2.svg" alt="" class="img-fluid mw-100">
        </div>
        <div class="col-md-4 error-middle">
            <img src="/img/Repeat Grid 2.svg" alt="" class="img-fluid m-100">
            <h1>{{ $t("oops!")}}</h1>
            <p>{{ $t("the page you requested could not be found")}}</p>
            <router-link :to="{name: 'dashboard'}" class="semi-button info-button">{{ $t("go to dashboard")}}</router-link>
        </div>
        <div class="col-md-4 error-right">
            <img src="/img/Group 338.svg" alt="" class="img-fluid mw-100">
        </div>
    </div>
</template>
<script>
    export default {}
</script>
